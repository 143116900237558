import { render, staticRenderFns } from "./deviceWorkDetail.vue?vue&type=template&id=59bd957f&scoped=true&"
import script from "./deviceWorkDetail.vue?vue&type=script&lang=js&"
export * from "./deviceWorkDetail.vue?vue&type=script&lang=js&"
import style0 from "./deviceWorkDetail.vue?vue&type=style&index=0&id=59bd957f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59bd957f",
  null
  
)

export default component.exports