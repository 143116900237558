<template>
	<div class="deviceWorkDetail" :style="{ 'background-image': 'url(' + bgImg + ')' }">
		<div class="deviceInfo">
			<div class="deviceInfoContentView">
				<div class="deviceDetailView">
					<img class="deviceImg" :src="image">
					<div class="deviceContent">
						<div class="deviceContentCell">
							<div class="deviceContentTitle">{{ deviceName }}</div>
							<div :class="{outWarranty:!inWarranty, inWarranty:inWarranty}" class="deviceContentStatus">
								{{ inWarranty == 1 ? '在保' : '不在保' }}
							</div>
							<div style="clear: both"></div>
						</div>

						<div class="content">
							<div class="smallTitle">设备分类</div>
							<div class="smallValue">{{ categoryName }}</div>
							<div style="clear: both"></div>
						</div>

						<div class="content">
							<div class="smallTitle">{{$VUEAPPDEVICECODE()}}</div>
							<div class="smallValue">{{ deviceCode }}</div>
							<div style="clear: both"></div>
						</div>

						<div class="content">
							<div class="smallTitle">报障时间</div>
							<div class="smallValue">{{ reportTime }}</div>
							<div style="clear: both"></div>
						</div>
					</div>
					<div class="line"></div>
				</div>
				<div class="content">
					<div class="title">订单编号</div>
					<div class="value">{{ serialNumber }}</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="detailTitle">报障信息</div>
				</div>

				<div class="content">
					<div class="title">报障人</div>
					<div class="value">
						{{ reportUser }}
					</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">联系电话</div>
					<div class="value">
						{{ dataInfo.phone||'-' }}
					</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">上门地址</div>
					<div class="value">
						{{ address }}
					</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">紧急度</div>
					<div class="value" :style="{color: severity == 5 ? 'black' : 'red'}">
						{{ severityTitle }}
					</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">设备状态</div>
					<div class="value" :style="{color: deviceStatus == 1 ? 'green' : 'red'}">
						{{ deviceStatusTitle }}
					</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">备用机器</div>
					<div class="value" :style="{color: dataInfo.standby == 1 ? 'green' : 'red'}">
						{{ dataInfo.standby?'有':'无' }}
					</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">预计维修时间</div>
					<div class="value">
						{{ dataInfo.estimatedRepairTime|formatDateTime }}
					</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">故障描述</div>
					<div class="value">{{ reasonDetail }}</div>
					<div style="clear: both"></div>
				</div>

				<van-grid v-if="photo1.length>0" :border="false" :column-num="3" class="responseDescribeDetailImgList"
					:gutter="10">
					<van-grid-item v-for="(item, index) in photo1" @click="showImageList(photo1, index)">
						<van-image :src="$replacePhoto(item.url)+'&scale=0.2'" />
					</van-grid-item>
				</van-grid>

				<div class="footerView" v-if="status==5">
					<div class="sureButton" @click="deal">处理</div>
					<div class="cancelButton" @click="cancel">取消</div>
				</div>
			</div>
		</div>


		<div class="deviceInfo" v-if="status==16||status==8||status==9||status==10||status==11||status==12">
			<div class="deviceInfoContentView">
				<div class="content">
					<div class="detailTitle">维修处理</div>
				</div>
				<div class="content">
					<div class="title">故障类型</div>
					<div class="value">{{ repairTypeTitle }}</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">故障原因</div>
					<div class="value">{{ repairDetail }}</div>
					<div style="clear: both"></div>
				</div>

				<van-grid v-if="photo2.length>0" :border="false" :column-num="3" class="responseDescribeDetailImgList"
					:gutter="10">

					<van-grid-item v-for="(item, index) in photo2" @click="showImageList(photo2, index)">
						<van-image :src="$replacePhoto(item.url)+'&scale=0.2'" />
					</van-grid-item>
				</van-grid>
			</div>
		</div>

		<div class="deviceInfo" v-if="status==16||status==8||status==9||status==10||status==11||status==12">
			<div class="deviceInfoContentView">
				<div class="content">
					<div class="detailTitle">费用结算</div>
				</div>
				<div class="content">
					<div class="title">更换零件</div>
					<div class="value">{{ repairComponent }}</div>
					<div style="clear: both"></div>
				</div>

				<div class="content">
					<div class="title">总费用</div>
					<div class="value">
						{{ amount }}元
					</div>
					<div style="clear: both"></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	let bgImg = require('../../../../../../static/iconImage/operationCenter.png')
	import deviceStatusOption from '@/mixins/deviceStatusOption/index.js'
	import shareDeviceOrderStatus from '@components/shareDeviceOrderStatus/shareDeviceOrderStatus'
	import severityOption from '@/mixins/severityOption/index.js'
	import deviceWorkerRepairOptions from '@components/deviceWorkerRepairOptions/deviceWorkerRepairOptions'
	import {
		ImagePreview
	} from 'vant'

	export default {
		name: 'deviceWorkDetail',
		mixins: [deviceStatusOption, shareDeviceOrderStatus, severityOption, deviceWorkerRepairOptions],
		components: {
			[ImagePreview.Component.name]: ImagePreview.Component
		},
		computed: {
			image() {
				if (this.$valueIsExist(this.dataInfo, 'photo') && this.dataInfo['photo'] != '') {
					let photo = this.dataInfo['photo']
					return `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}`
				}
				return this.$replacePhoto('')
			},
			repairComponent() {
				if (this.$valueIsExist(this.dataInfo, 'repairComponent') == false) {
					return ''
				}
				return this.dataInfo['repairComponent']
			},
			photo1() {
				if (this.$valueIsExist(this.dataInfo, 'photos1') == false) {
					return []
				}
				return this.dataInfo['photos1']
			},
			photo2() {
				if (this.$valueIsExist(this.dataInfo, 'photos2') == false) {
					return []
				}
				return this.dataInfo['photos2']
			},
			repairDetail() {
				if (this.$valueIsExist(this.dataInfo, 'repairDetail') == false) {
					return ''
				}
				return this.dataInfo['repairDetail']
			},
			reasonDetail() {
				if (this.$valueIsExist(this.dataInfo, 'detail') == false) {
					return ''
				}
				return this.dataInfo['detail']
			},
			repairTypeTitle() {
				let repairType = 0
				if (this.$valueIsExist(this.dataInfo, 'repairType')) {
					repairType = this.dataInfo['repairType']
				}
				return this.getStatusTitle(repairType)
			},
			severity() {
				let severity = 0
				if (this.$valueIsExist(this.dataInfo, 'severity')) {
					severity = this.dataInfo['severity']
				}
				return severity
			},
			severityTitle() {
				return this.getSeverityStatusTitle(this.severity)
			},
			deviceStatus() {
				let deviceStatus = 0
				if (this.$valueIsExist(this.dataInfo, 'deviceStatus')) {
					deviceStatus = this.dataInfo['deviceStatus']
				}
				return deviceStatus
			},
			deviceStatusTitle() {
				return this.getDeviceStatusTitle(this.deviceStatus)
			},
			deviceName() {
				return this.$getDataValue(this.dataInfo, 'deviceName', '')
			},
			inWarranty() {
				return this.$getDataValue(this.dataInfo, 'deviceName', false)
			},
			statusTitle() {
				if (this.status == 16 || this.status == 11) {
					return '查看'
				}
				return '维修'
			},
			status() {
				let status = 0
				if (this.$valueIsExist(this.dataInfo, 'status')) {
					status = this.dataInfo['status']
				}
				return status
			},
			orderstatus() {
				return this.getStatus(this.status, this.workerRepairOptions)
			},
			deviceCode() {
				if (this.$valueIsExist(this.dataInfo, 'deviceCode') == false) {
					return ''
				}
				return this.dataInfo['deviceCode']
			},
			categoryName() {
				if (this.$valueIsExist(this.dataInfo, 'categoryName') == false) {
					return ''
				}
				return this.dataInfo['categoryName']
			},
			address() {
				if (this.$valueIsExist(this.dataInfo, 'address') == false) {
					return ''
				}
				return this.dataInfo['address']
			},
			serialNumber() {
				if (this.$valueIsExist(this.dataInfo, 'serialNumber') == false) {
					return ''
				}
				return this.dataInfo['serialNumber']
			},
			amount() {
				if (this.$valueIsExist(this.dataInfo, 'amount') == false) {
					return '0'
				}
				return this.dataInfo['amount']
			},
			reportUser() {
				if (this.$valueIsExist(this.dataInfo, 'reportUser') == false) {
					return ''
				}
				return this.dataInfo['reportUser']
			},
			reportTime() {
				if (this.$valueIsExist(this.dataInfo, 'reportTime') == false) {
					return ''
				}
				return this.formatDate(this.dataInfo['reportTime'])
			},
			repairTime() {
				if (this.$valueIsExist(this.dataInfo, 'repairTime') == false) {
					return ''
				}
				return this.formatDate(this.dataInfo['repairTime'])
			}
		},
		data() {
			return {
				bgImg: bgImg,
				dataInfo: {}
			}
		},
		mounted() {
			this.getDataInfo()
		},
		methods: {
			deal: function() {
				let query = this.$route.query
				let id = ''
				if (this.$valueIsExist(query, 'id')) {
					id = query['id']
				}
				this.$push('deviceView1/deviceWorkerDeal', {
					id: id
				})
			},

			cancel: function() {
				let startCancel = () => {
					localStorage.setItem('updateWorkerList', new Date().getTime())
					return new Promise(resolve => {
						let query = this.$route.query
						let id = ''
						if (this.$valueIsExist(query, 'id')) {
							id = query['id']
						}

						this.$api.deviceRepair
							.reportDeny({
								id: id,
								remark: ''
							}).then(res => {

								resolve({
									result: true
								})
							}).catch(e => {
								resolve({
									result: false
								})
							})
					})
				}

				this.$dialog.confirm({
					message: `是否拒绝该工单`,
					beforeClose: (action, done) => {
						if (action === 'confirm') {
							this.loading = true
							startCancel().then(res => {
								this.loading = false
								done()
								if (res.result) {
									this.$toast.success('提交成功')
									localStorage.setItem('updateWorkerList', new Date().getTime())
									this.$back()
								} else {
									this.$toast.fail('提交失败')
								}
							})
						} else {
							done()
						}
					}
				})
			},
			showImageList: function(imagelist = [], index = 0) {
				let list = []
				for (let i = 0; i < imagelist.length; i++) {
					let data = imagelist[i]
					list.push(this.$replacePhoto(data.url))
				}
				if (list.length == 0) {
					return
				}
				ImagePreview(list, index)
			},
			getDataInfo: function() {
				let getData = () => {
					return new Promise(resolve => {
						let query = this.$route.query
						let id = ''
						if (this.$valueIsExist(query, 'id')) {
							id = query['id']
						}

						this.$api.deviceRepair
							.reportDataInfo({
								id: id,
								photo: '1,2,3,4,5,6'
							}).then(res => {
								if (this.$valueIsExist(res, 'data') == false) {
									resolve({
										result: false,
										data: {},
										dataList: []
									})
								}
								let report = {}
								if (this.$valueIsExist(res.data, 'report')) {
									report = res.data['report']
								}
								let dataList = []
								if (this.$valueIsExist(res.data, 'data')) {
									dataList = res.data['data']
								}
								resolve({
									result: true,
									data: report,
									dataList: dataList
								})
							}).catch(e => {
								resolve({
									result: false,
									data: {},
									dataList: []
								})
							})
					})
				}

				getData().then(res => {
					this.dataInfo = res.data
					this.dataList = res.dataList
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@styles/variables.scss";

	.deviceWorkDetail {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: 100%;
		padding: 10px 0px 10px 0px;

		.inWarranty {
			background-color: $color_primary;
		}

		.outWarranty {
			background-color: red;
		}


		.deviceInfo {
			width: 100%;
			padding: 0px 10px 10px 10px;

			.deviceInfoContentView {
				width: 100%;
				background-color: white;
				border-radius: 6px;
				padding: 5px 10px 0px 10px;


				.deviceDetailView {
					width: 100%;
					margin-bottom: 10px;

					.line {
						float: right;
						width: 100%;
						height: 1px;
						background-color: $border_color1;
						margin-bottom: 15px;
					}

					.deviceImg {
						width: 80px;
						height: 80px;
						border-radius: 8px;
						float: left;
						padding: 10px;
					}

					.deviceContent {
						width: calc(100% - 80px);
						float: right;
						margin-bottom: 10px;

						.deviceContentCell {

							margin-bottom: 5px;

							.deviceContentTitle {
								float: left;
								width: calc(100% - 60px);
								line-height: 24px;
								font-size: 15px;
								font-weight: bold;
								word-wrap: break-word;
								word-break: break-all;
							}

							.deviceContentStatus {
								float: right;
								width: 60px;
								height: 24px;
								line-height: 24px;
								font-size: 14px;
								word-wrap: break-word;
								word-break: break-all;
								color: white;
								text-align: center;
								border-radius: 6px;
								overflow: hidden;
								background-color: red;
							}
						}
					}


				}

				.footerView {
					width: 100%;
					height: 50px;
					padding: 0px 0px 10px 0px;

					.sureButton {
						margin-left: 10px;
						width: 80px;
						height: 36px;
						border-radius: 18px;
						overflow: hidden;
						float: right;
						font-size: 13px;
						line-height: 36px;
						text-align: center;
						color: $color_primary;
						border: 1px solid $color_primary;
					}

					.cancelButton {
						width: 80px;
						height: 36px;
						border-radius: 18px;
						overflow: hidden;
						float: right;
						font-size: 13px;
						line-height: 36px;
						text-align: center;
						color: red;
						border: 1px solid red;
					}
				}


				.contentMsg {
					width: 100%;
					height: 25px;
					line-height: 25px;
					font-size: 15px;
					font-weight: bold;
					margin-bottom: 10px;
				}

				.content {
					width: 100%;
					margin-bottom: 5px;


					.detailTitle {
						width: 100%;
						line-height: 20px;
						font-size: 14px;
						font-weight: bold;
						word-wrap: break-word;
						word-break: break-all;
					}

					.smallTitle {
						width: 100px;
						height: 20px;
						line-height: 20px;
						font-size: 12px;
						float: left;
						color: $color3;
					}

					.smallValue {
						width: calc(100% - 110px);
						line-height: 20px;
						font-size: 12px;
						text-align: right;
						float: right;
						word-wrap: break-word;
						word-break: break-all;
					}

					.title {
						width: 100px;
						height: 20px;
						line-height: 20px;
						font-size: 14px;
						float: left;
						color: $color3;
					}

					.value {
						width: calc(100% - 110px);
						line-height: 20px;
						font-size: 14px;
						text-align: right;
						float: right;
						word-wrap: break-word;
						word-break: break-all;
					}

					.danger {
						color: red;
					}
				}
			}
		}
	}
</style>
